<template>
  <div class="privacy">
    <div class="privacy-title">{{ $t("lang.law.1") }}</div>
    <div class="privacy-table">
      <table class="privacy-table-time" width="100%">
        <tr>
          <td>{{ $t("lang.law.2") }}</td>
          <td>{{ $t("lang.law.3") }}</td>
        </tr>
        <tr>
          <td>{{ $t("lang.law.4") }}</td>
          <td>{{ $t("lang.law.5") }}</td>
        </tr>
      </table>
    </div>
    <div class="privacy-list">
      <div
        class="privacy-list-item"
        :ref="item.ref"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="privacy-list-item-title">{{ $t(item.bigTitle) }}</div>
        <div class="privacy-list-item-info" v-if="item.bigTitleInfo.length">
          <p
            v-for="(item1, index1) in item.bigTitleInfo"
            :key="index + '-' + index1"
          >
            {{ $t(item1) }}
          </p>
          <p
            v-for="item1 in item.linkList"
            :key="index + '-' + item1.ref"
            class="privacy-list-item-info-link"
          >
            {{ $t(item1.title) }}
          </p>
        </div>
        <div class="privacy-list-item-medium">
          <div
            class="privacy-list-item-medium-item"
            v-for="(item2, index2) in item.medium"
            :key="index + '-' + item2.title + '-' + index2"
          >
            <div class="privacy-list-item-medium-item-title">
              {{ $t(item2.title) }}
            </div>
            <div class="privacy-list-item-medium-item-Info">
              <p
                v-for="(item3, index3) in item2.Info"
                :key="index + '-' + item2.title + '-' + index2 + '-' + index3"
              >
                {{ $t(item3) }}
              </p>
            </div>
            <div class="privacy-list-item-medium-item-small">
              <div
                class="privacy-list-item-medium-item-small-item"
                v-for="(item3, index3) in item2.small"
                :key="index + '-' + item2.title + '-' + index3"
              >
                <template v-if="!item3.isSlot">
                  <div class="privacy-list-item-medium-item-small-item-title">
                    {{ $t(item3.title) }}
                  </div>
                  <div class="privacy-list-item-medium-item-small-item-Info">
                    <p
                      v-for="(item4, index4) in item3.Info"
                      :key="
                        index +
                        '-' +
                        item2.title +
                        '-' +
                        index2 +
                        '-' +
                        index3 +
                        '-' +
                        index4
                      "
                    >
                      {{ $t(item4) }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="privacy-list-item-medium-item-small-item-table">
                    <table
                      class="
                        privacy-list-item-medium-item-small-item-table-time
                      "
                      width="100%"
                    >
                      <tr v-for="(item, index) in tableList" :key="index">
                        <td
                          :key="index + '-' + index1"
                          v-for="(item1, index1) in item"
                        >
                          <span v-if="index === 0 || index1 !== 4">
                            {{ $t(item1) }}
                          </span>
                          <a v-else target="_blank" :href="$t(item1)">{{
                            $t(item1)
                          }}</a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
  data() {
    return {
      list: [
        {
          ref: "zero",
          bigTitle: "lang.law.6",
          bigTitleInfo: [
            "lang.law.7",
            "lang.law.8",
            "lang.law.9",
            "lang.law.10",
            "lang.law.11",
            "lang.law.12",
            "lang.law.13",
          ],
          linkList: [
            { ref: "one", title: "lang.law.14" },
            { ref: "two", title: "lang.law.15" },
            { ref: "three", title: "lang.law.16" },
            { ref: "four", title: "lang.law.17" },
            { ref: "five", title: "lang.law.18" },
            { ref: "six", title: "lang.law.19" },
            { ref: "seven", title: "lang.law.20" },
            { ref: "eight", title: "lang.law.21" },
            { ref: "nine", title: "lang.law.22" },
            { ref: "ten", title: "lang.law.23" },
          ],
        },
        {
          bigTitle: "lang.law.24",
          bigTitleInfo: [],
          ref: "one",
          medium: [
            {
              title: "lang.law.25",
              Info: ["lang.law.26"],
              small: [
                {
                  title: "lang.law.27",
                  Info: ["lang.law.28", "lang.law.29"],
                },
                {
                  title: "lang.law.30",
                  Info: ["lang.law.31"],
                },
                {
                  title: "lang.law.32",
                  Info: ["lang.law.33"],
                },
                {
                  title: "lang.law.34",
                  Info: ["lang.law.35", "lang.law.36"],
                },
                {
                  title: "lang.law.37",
                  Info: ["lang.law.38", "lang.law.39", "lang.law.40"],
                },
                {
                  title: "lang.law.41",
                  Info: ["lang.law.42", "lang.law.43"],
                },
                {
                  title: "lang.law.44",
                  Info: ["lang.law.45", "lang.law.46"],
                },
                {
                  title: "lang.law.47",
                  Info: [
                    "lang.law.48",
                    "lang.law.49",
                    "lang.law.50",
                    "lang.law.51",
                    "lang.law.52",
                  ],
                },
                {
                  title: "lang.law.53",
                  Info: ["lang.law.54", "lang.law.55"],
                },
              ],
            },
            {
              title: "lang.law.56",
              Info: [],
              small: [
                {
                  title: "",
                  Info: [
                    "lang.law.57",
                    "lang.law.58",
                    "lang.law.59",
                    "lang.law.60",
                    "lang.law.61",
                    "lang.law.62",
                    "lang.law.63",
                    "lang.law.64",
                    "lang.law.65",
                    "lang.law.66",
                    "lang.law.67",
                    "lang.law.68",
                    "lang.law.69",
                    "lang.law.70",
                    "lang.law.71",
                    "lang.law.72",
                    "lang.law.73",
                    "lang.law.74",
                    "lang.law.75",
                    "lang.law.76",
                    "lang.law.77",
                    "lang.law.78",
                    "lang.law.79",
                  ],
                },
              ],
            },
            {
              title: "lang.law.80",
              Info: [],
              small: [
                {
                  title: "",
                  Info: ["lang.law.81", "lang.law.82", "lang.law.83"],
                },
              ],
            },
            {
              title: "lang.law.84",
              Info: [],
              small: [
                {
                  title: "",
                  Info: ["lang.law.85"],
                },
              ],
            },
            {
              title: "lang.law.86",
              Info: [],
              small: [
                {
                  title: "",
                  Info: ["lang.law.87"],
                },
              ],
            },
            {
              title: "lang.law.88",
              Info: [],
              small: [
                {
                  title: "",
                  Info: [
                    "lang.law.89",
                    "lang.law.90",
                    "lang.law.91",
                    "lang.law.92",
                    "lang.law.93",
                    "lang.law.94",
                    "lang.law.95",
                    "lang.law.96",
                    "lang.law.97",
                    "lang.law.98",
                    "lang.law.99",
                    "lang.law.100",
                    "lang.law.101",
                    "lang.law.102",
                    "lang.law.103",
                  ],
                },
              ],
            },
            {
              title: "lang.law.104",
              Info: [],
              small: [
                {
                  title: "",
                  Info: ["lang.law.105"],
                },
              ],
            },
            {
              title: "lang.law.106",
              Info: [],
              small: [
                {
                  title: "",
                  Info: ["lang.law.107"],
                },
              ],
            },
          ],
        },
        {
          bigTitle: "lang.law.108",
          bigTitleInfo: ["lang.law.109", "lang.law.110"],
          ref: "two",
          medium: [
            {
              title: "lang.law.111",
              Info: ["lang.law.112"],
              small: [],
            },
            {
              title: "lang.law.113",
              Info: ["lang.law.114"],
              small: [],
            },
            {
              title: "lang.law.115",
              Info: ["lang.law.116"],
              small: [],
            },
            {
              title: "lang.law.117",
              Info: ["lang.law.118"],
              small: [],
            },
            {
              title: "lang.law.119",
              Info: ["lang.law.120"],
              small: [],
            },
            {
              title: "lang.law.121",
              Info: ["lang.law.122"],
              small: [],
            },
          ],
        },
        {
          bigTitle: "lang.law.123",
          bigTitleInfo: ["lang.law.124"],
          ref: "three",
          medium: [
            {
              title: "lang.law.125",
              Info: ["lang.law.126"],
              small: [],
            },
            {
              title: "lang.law.127",
              Info: ["lang.law.128"],
              small: [],
            },
            {
              title: "lang.law.129",
              Info: ["lang.law.130"],
              small: [],
            },
            {
              title: "lang.law.131",
              Info: ["lang.law.132"],
              small: [],
            },
            {
              title: "lang.law.133",
              Info: ["lang.law.134", "lang.law.135"],
              small: [],
            },
            {
              title: "lang.law.136",
              Info: ["lang.law.137", "lang.law.138"],
              small: [],
            },
          ],
        },
        {
          bigTitle: "lang.law.139",
          bigTitleInfo: [],
          ref: "four",
          medium: [
            {
              title: "lang.law.140",
              Info: ["lang.law.141"],
              small: [
                {
                  title: "",
                  Info: [
                    "lang.law.142",
                    "lang.law.143",
                    "lang.law.144",
                    "lang.law.145",
                  ],
                },
              ],
            },
            {
              title: "lang.law.146",
              Info: ["lang.law.147"],
              small: [],
            },
            {
              title: "lang.law.148",
              Info: ["lang.law.149"],
              small: [],
            },
            {
              title: "lang.law.150",
              Info: ["lang.law.151", "lang.law.152", "lang.law.153"],
              small: [],
            },
          ],
        },
        {
          bigTitle: "lang.law.154",
          bigTitleInfo: [],
          ref: "five",
          medium: [
            {
              title: "lang.law.155",
              Info: ["lang.law.156", "lang.law.157"],
              small: [
                {
                  title: "lang.law.158",
                  Info: ["lang.law.159", "lang.law.160", "lang.law.161"],
                },
                {
                  title: "lang.law.162",
                  Info: ["lang.law.163"],
                },
                {
                  title: "lang.law.164",
                  Info: ["lang.law.165", "lang.law.166", "lang.law.167"],
                },
                {
                  isSlot: true,
                  title: "",
                  Info: [],
                },
                {
                  title: "",
                  Info: [
                    "lang.law.218",
                    "lang.law.219",
                    "lang.law.220",
                    "lang.law.221",
                    "lang.law.222",
                    "lang.law.223",
                    "lang.law.224",
                    "lang.law.225",
                    "lang.law.226",
                    "lang.law.227",
                  ],
                },
                {
                  title: "lang.law.228",
                  Info: [
                    "lang.law.229",
                    "lang.law.230",
                    "lang.law.231",
                    "lang.law.232",
                    "lang.law.233",
                    "lang.law.234",
                  ],
                },
                {
                  title: "lang.law.235",
                  Info: ["lang.law.236", "lang.law.237", "lang.law.238"],
                },
              ],
            },
          ],
        },
        {
          bigTitle: "lang.law.243",
          bigTitleInfo: [
            "lang.law.244",
            "lang.law.245",
            "lang.law.246",
            "lang.law.247",
            "lang.law.248",
          ],
          ref: "six",
          medium: [],
        },
        {
          bigTitle: "lang.law.249",
          bigTitleInfo: ["lang.law.250", "lang.law.251"],
          ref: "seven",
          medium: [],
        },
        {
          bigTitle: "lang.law.252",
          bigTitleInfo: ["lang.law.253"],
          ref: "eight",
          medium: [],
        },
        {
          bigTitle: "lang.law.254",
          bigTitleInfo: ["lang.law.255", "lang.law.256", "lang.law.257"],
          ref: "eight",
          medium: [],
        },
        {
          bigTitle: "lang.law.258",
          bigTitleInfo: [
            "lang.law.259",
            "lang.law.260",
            "lang.law.261",
            "lang.law.262",
          ],
          ref: "ten",
          medium: [],
        },
      ],
      tableList: [
        [
          "lang.law.168",
          "lang.law.169",
          "lang.law.170",
          "lang.law.171",
          "lang.law.172",
        ],
        [
          "lang.law.173",
          "lang.law.174",
          "lang.law.175",
          "lang.law.176",
          "lang.law.177",
        ],
        [
          "lang.law.178",
          "lang.law.179",
          "lang.law.180",
          "lang.law.181",
          "lang.law.182",
        ],
        [
          "lang.law.183",
          "lang.law.184",
          "lang.law.185",
          "lang.law.186",
          "lang.law.187",
        ],
        [
          "lang.law.188",
          "lang.law.189",
          "lang.law.190",
          "lang.law.191",
          "lang.law.192",
        ],
        [
          "lang.law.193",
          "lang.law.194",
          "lang.law.195",
          "lang.law.196",
          "lang.law.197",
        ],
        [
          "lang.law.198",
          "lang.law.199",
          "lang.law.200",
          "lang.law.201",
          "lang.law.202",
        ],
        [
          "lang.law.203",
          "lang.law.204",
          "lang.law.205",
          "lang.law.206",
          "lang.law.207",
        ],
        [
          "lang.law.208",
          "lang.law.209",
          "lang.law.210",
          "lang.law.211",
          "lang.law.212",
        ],
        [
          "lang.law.213",
          "lang.law.214",
          "lang.law.215",
          "lang.law.216",
          "lang.law.217",
        ],
      ],
    };
  },
};
</script>

<style scoped lang="stylus">
.privacy {
  color: #333;
  width: 1550px;
  margin: 0 auto;
  min-height: 600px;
  font-weight: 500;

  &-title {
    margin-top: 120px;
    font-size: 42px;
    margin-bottom: 40px;
  }

  &-table {
    font-size: 16px;
    width: 100%;
    border-collapse: collapse;

    &-time {
      border-collapse: collapse;
      border: 1px solid #eeeeee;
    }

    td {
      padding: 25px;
      border: 1px solid #eeeeee;
    }
  }

  &-list {
    &-item {
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;

      &-title {
        color: #a53221;
        font-size: 36px;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      &-info {
        font-size: 16px;

        > p {
          margin-bottom: 10px;
        }

        &-link {
          cursor: pointer;
          color: #2156a5;
        }

        &-link:hover {
          color: #337ab7;
        }
      }

      &-medium {
        &-item {
          &-title {
            font-size: 30px;
            margin-top: 24px;
            margin-bottom: 15px;
            color: #a53221;
          }

          &-Info {
            font-size: 16px;

            > p {
              margin-bottom: 10px;
            }
          }

          &-small {
            &-item {
              &-title {
                font-size: 16px;
                margin-bottom: 15px;
                margin-top: 24px;
                color: #a53221;
              }

              &-Info {
                font-size: 16px;

                > p {
                  margin-bottom: 10px;
                }
              }

              &-table {
                font-size: 16px;
                width: 100%;
                border-collapse: collapse;

                &-time {
                  border-collapse: collapse;
                  border: 1px solid #eeeeee;
                  table-layout: fixed;

                  tr:first-child {
                    background-color: #f7f8f7;
                    color: #000;
                  }

                  td {
                    padding: 15px;
                    border: 1px solid #eeeeee;

                    a {
                      word-break: break-all;
                      color: #2156a5;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &-item:last-child {
      border-bottom: 0 solid #eee;
    }
  }
}

@media screen and (max-width: 1550px) {
  .privacy {
    width: 1165px;

    &-title {
      font-size: 32px;
      margin-top: 100px;
      margin-bottom: 25px;
    }

    &-table {
      td {
        padding: 20px;
      }
    }

    &-list {
      &-item {
        &-title {
          font-size: 32px;
        }

        &-info {
          font-size: 16px;
        }
      }
    }
  }

  .privacy-list-item-medium-item-small-item-table-time td {
    padding: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .privacy {
    width: 960px;

    &-title {
      font-size: 30px;
      margin-top: 100px;
      margin-bottom: 25px;
    }

    &-table {
      td {
        padding: 20px;
      }
    }

    &-list {
      &-item {
        &-title {
          font-size: 30px;
        }

        &-info {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .privacy {
    width: 700px;

    &-title {
      font-size: 28px;
      margin-top: 100px;
      margin-bottom: 25px;
    }

    &-table {
      td {
        padding: 20px;
      }
    }

    &-list {
      &-item {
        &-title {
          font-size: 28px;
        }

        &-info {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .privacy {
    width: 90%;

    &-title {
      font-size: 24px;
      margin-top: 90px;
      margin-bottom: 25px;
    }

    &-table {
      td {
        padding: 10px;
      }
    }

    &-list {
      &-item {
        &-title {
          font-size: 24px;
        }

        &-info {
          font-size: 14px;
        }
      }
    }
  }

  .privacy-list-item-medium-item-small-item-Info {
    font-size: 14px;
  }

  .privacy-list-item-medium-item-title {
    font-size: 22px;
  }

  .privacy-list-item-medium-item-small-item-table {
    font-size: 14px;
  }
}
</style>
